import React, { useState } from 'react';
import axios from 'axios';
import ModalPopup from '../ModelPopup/ModalPopup';
import './CreateFolderForm.css';
import ErrorPopup from '../ModelPopup/ErrorPopup';
import CheckBoxComponent from '../ModelPopup/CheckBoxComponent'
import JSZip from 'jszip';
import { updatePipelineConfiguration } from '../AccessRepo/BuildDetails';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { accessToken } from '../../../Hook/userRoles';


const UploadFiles = ({ onCloseButton, branchName, folderPathRecived }) => {
  const { instance, accounts } = useMsal();
  let result;
  const [selectedFile, setSelectedFile] = useState(null);
  const dataForDescription = folderPathRecived;
  const [commitMessage, setCommitMessage] = useState(() => {
    if (branchName && dataForDescription) {
      if (!dataForDescription) {
        return branchName;
      } else {
        const processedDescription = dataForDescription.replaceAll('/', '_').replace('.gitignore', '');
        const result = ` ${branchName}_${processedDescription}`;
        // Check if the part after the underscore is empty or only whitespace
        const underscoreIndex = result.lastIndexOf('_');
        if (underscoreIndex !== -1) {
          const afterUnderscore = result.substring(underscoreIndex + 1);
          if (/^\s*$/.test(afterUnderscore)) {
            // Remove the underscore at the end
            return result.substring(0, underscoreIndex);
          }
        }
        return result;
      }
    } else {
      return `Upload software or files`;
    }
  });
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [checkBox, setcheckBox] = useState('');
  const branch = window.branch;
  const folderPath = folderPathRecived;

  const handleClose = () => {
    onCloseButton();
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleCommitMessageChange = (event) => {
    setCommitMessage(event.target.value);
  };

  const closeErrorPopup = () => {
    onCloseButton();
  };
  const handelCancelClickoncheckbox = () => {
    setcheckBox(false);
  }
  // Function to show loading animation
  // Function to show loading animation
  function showLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'flex';
    }
  }

  // Function to hide loading animation
  function hideLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'none';
    }
  }




  const handeClickonCheckbox = () => {
    setcheckBox(false);
    showLoading();
    if (selectedFile) {
      if (selectedFile.name.endsWith('.zip')) {
        handleZipFileUpload();
      } else {
        handleSingleFileUpload();
      }
    } else {
      setMessage('Please select a file to upload');
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      hideLoading();
      setIsErrorPopupOpen(true);
    }

  }


  const pullLatest = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_FETCH_ALL_BRANCHES, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        },
      });
      const repositories = response.data.value;
      const modifiedRepoData = repositories.map(repo => ({
        name: repo.name.replace("refs/heads/", ''),
        creatorDisplayName: repo.creator.displayName,
        isOpen: false,
        objectId: repo.objectId
      }));

      window.objectId = modifiedRepoData.find(data => data.name === branchName)?.objectId;
    } catch (error) {
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      setMessage(`There was a problem uploading the files. Please refresh the page and try again.`);
      setIsErrorPopupOpen(true);
      hideLoading();
      return;
    }
  };


  const deleteFiles = async (branchName, directoryPath) => {
    try {
      // Fetch all the files from the directory
      const url = process.env.REACT_APP_BASE_URL + `items?scopePath=${directoryPath}&recursionLevel=OneLevel&versionDescriptor.version=${branchName}&api-version=7.1-preview.1`;
      const filesResponse = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        },
      });
      const filesToDelete = filesResponse.data.value.map(file => file.path);

      // Prepare the commit object
      const commit = {
        refUpdates: [
          {
            name: `refs/heads/${branchName}`,
            oldObjectId: window.objectId // You'll need to fill this in with the correct value
          }
        ],
        "commits": [
          {
            "comment": "Removed default image file.",
            "changes": [
              {
                "changeType": "delete",
                "item": {
                  "path": filesToDelete[0]
                }
              }
            ]
          }
        ]
      };

      // Push the commit
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}pushes?api-version=4.1`, commit, {
        headers: {
          Authorization: `Bearer ${await accessToken(instance, accounts)}`,
        },
      });
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      if (response.status !== 201 && response.status !== 200) {
        setMessage(`There was a problem uploading the files. Please refresh the page and try again.`);
        setIsErrorPopupOpen(true);
        hideLoading();
        return;
      }
    } catch (error) {
      setMessage(`There was a problem uploading the files. Please refresh the page and try again.`);
      setIsErrorPopupOpen(true);
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      hideLoading();
      return;
    }
  };


  const uploadFile = async (changes) => {
    if (commitMessage === null || commitMessage === '') {
      setMessage(`Please enter the description message to upload software.`);
      setIsErrorPopupOpen(true);
      return { success: false };
    }
    await pullLatest();

    try {
      document.getElementById('AppContainer')?.classList?.add('excluxivityEnable');

      // Check if changes include the Submission directory
      const isSubmittedDirectory = changes.some(change => change.item.path.includes('Submission'));

      if (isSubmittedDirectory) {
        if (folderPath.split('/').pop() === 'Submission') {
          return { success: false, status: `Please choose currect directory to upload software.` };
        } else {
          await deleteFiles(branchName, folderPath + '/');
          await pullLatest();
        }
      }


      // Proceed with the upload operation
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}pushes?api-version=4.1`,
        {
          refUpdates: [
            {
              name: `refs/heads/${branch}`,
              oldObjectId: window.objectId,
            },
          ],
          commits: [
            {
              comment: commitMessage,
              changes: changes,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${await accessToken(instance, accounts)}`,
            'Content-Type': 'application/json',
          },
        }
      );

      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      changes = null;
      if (folderPath.includes('Submission')) {
        const responceData = await updatePipelineConfiguration(folderPath, null, null, null, branchName, process.env.REACT_APP_ApprovalPipeline, null, instance, accounts);
        if (responceData.showMessage === true){
            setMessage(responceData.message);
            setIsErrorPopupOpen(prev => !prev); // Toggle the state
        } 
      }

      return { success: true };
    } catch (error) {
      console.error('Error pushing files:', error.response ? error.response.data : error.message);
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      return { success: false };
    }
  };


  // Function to convert Uint8Array to base64
  function uint8ArrayToBase64(bytes) {
    let binary = '';
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const readFileContent = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
    });
  };

  const handleZipFileUpload = async () => {
    if (selectedFile) {
      const zip = await JSZip.loadAsync(selectedFile);
      const files = Object.values(zip.files);
      const changes = [];

      for (const file of files) {
        if (!file.dir) { // Check if it's not a directory (not ending with "/")
          const fileContent = await file.async('uint8array'); // Read file as Uint8Array
          const fileContentBase64 = uint8ArrayToBase64(fileContent); // Convert Uint8Array to base64
          const fullPath = file.name; // Get the full path from the ZIP file

          // Remove the first folder dynamically
          const firstSlashIndex = fullPath.indexOf('/');
          const pathWithoutFirstFolder = fullPath.substring(firstSlashIndex + 1);

          const pushFilepath = folderPath ? `/${folderPath}/${pathWithoutFirstFolder}` : `/${pathWithoutFirstFolder}`;

          changes.push({
            changeType: 'add',
            item: {
              path: pushFilepath,
            },
            newContent: {
              content: fileContentBase64,
              contentType: 'base64Encoded', // Specify content type as base64
            },
          });
        }
      }

      const result = await uploadFile(changes);

      if (result.success) {
        setMessage('Files uploaded successfully!');
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        hideLoading();
      } else {
        setMessage(`There was a problem uploading the files. Please refresh the page and try again.`);
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        hideLoading();
      }

      setIsErrorPopupOpen(true);
    } else {
      setMessage('Please select a file to upload');
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      hideLoading();
      setIsErrorPopupOpen(true);
    }
  };




  const handleSingleFileUpload = async () => {
    if (selectedFile) {
      const fileContentArrayBuffer = await readFileContent(selectedFile);
      const fileContentUint8Array = new Uint8Array(fileContentArrayBuffer);
      const fileContentBase64 = uint8ArrayToBase64(fileContentUint8Array); // Convert Uint8Array to base64

      const changes = [
        {
          changeType: 'add',
          item: {
            path: folderPath ? `/${folderPath}/${selectedFile.name}` : `/${selectedFile.name}`,
          },
          newContent: {
            content: fileContentBase64,
            contentType: 'base64Encoded', // Specify content type as base64
          },
        },
      ];

      const result = await uploadFile(changes);

      if (result.success) {
        setMessage('File uploaded successfully');
        hideLoading();
      } else {
        setMessage(`There was a problem uploading the files. Please refresh the page and try again.`);
        document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
        hideLoading();
      }

      setIsErrorPopupOpen(true);
    } else {
      setMessage('Please select a file to upload');
      setIsErrorPopupOpen(true);
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      hideLoading();
    }
  };



  const handleUpload = () => {
    if(selectedFile){
      const checkForReleaseDoc = folderPathRecived;
      if (checkForReleaseDoc.split('/').pop() === 'BusinessReleaseDocs') {
        setcheckBox(false);
        handeClickonCheckbox();
      } else {
        setcheckBox(true);
      }
    } else {
      setMessage('Please select a file to upload');
      document.getElementById('AppContainer')?.classList?.remove('excluxivityEnable');
      hideLoading();
      setIsErrorPopupOpen(true);
    }
  };

  return (
    <AuthenticatedTemplate>
      <ModalPopup onClose={handleClose}>
        <div className='container-uploadFiles'>
          <div className='lableTextForCreateBranch'>Upload Software or Files</div>
          {folderPath.includes('Submission') && <div className="note">
               Please ensure you are uploading to correct path, e.g.,'Platform0.1 > Submission > Software Module >'. Verify all files before proceed.
          </div>}
          <div className='input-container'>
            <label htmlFor="file" className='lebel-width'>Select File:</label>
            <input type="file" className='choseFiles-width' id="file" onChange={handleFileChange} />
          </div>
          <div className='input-container'>
            <label htmlFor="commitMessage" className='lebel-width'>Description:</label>
            <input type="text" id="commitMessage" placeholder="Enter Description" value={commitMessage} onChange={handleCommitMessageChange} />
          </div>
          <button className='css-Button-Classes' disabled={!selectedFile || isErrorPopupOpen} selectedFile onClick={handleUpload}>Upload File</button>
          {isErrorPopupOpen && (
            <ErrorPopup message={message} onClose={closeErrorPopup} />
          )}
          {checkBox && <CheckBoxComponent onokButtonClick={handeClickonCheckbox} oncancelButtonClick={handelCancelClickoncheckbox} />}
        </div>
      </ModalPopup>
    </AuthenticatedTemplate>
  );
};

export default UploadFiles;
