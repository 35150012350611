import  { useState ,useEffect} from "react";
import { useMsal } from '@azure/msal-react';

export const userKinds = {
  MODULE_OWNER: process.env.REACT_APP_UR_M_O,
  INTEGRATION_USER: process.env.REACT_APP_UR_I_U,
  TESTING: process.env.REACT_APP_UR_T,
  PROMOTERS: process.env.REACT_APP_UR_P,
  APPROVERS: process.env.REACT_APP_UR_A,
  AFTERSALES: process.env.REACT_APP_UR_A_S,
  MANUFACTURING: process.env.REACT_APP_UR_M,
  VCAT : process.env.REACT_APP_UR_V,
};


// Function to show loading animation
function showLoading() {
  var loadingContainer = document.getElementById('loadingContainer');
  if (loadingContainer && loadingContainer.style) {
    loadingContainer.style.display = 'flex';
  }
}

// Function to hide loading animation
function hideLoading() {
  var loadingContainer = document.getElementById('loadingContainer');
  if (loadingContainer && loadingContainer.style) {
    loadingContainer.style.display = 'none';
  }
}

export async function accessToken(instance,account){
  try {
        let responseToken = await instance.acquireTokenSilent({
            scopes: [process.env.REACT_APP_SECRET],
            account: account[0],
          }); 
        return responseToken.accessToken;
      } catch(e){
          console.log('Error');
      }
};


export const accessRoles = async (instance,accounts) => {
  try {
    const responseToken = await instance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_SECRET],
      account: accounts[0],
    });
    return responseToken;
  } catch (e){
    hideLoading();
    console.log('Error');
  } 
};


export function useUserAuthorization({user = null }) {
  const { instance, accounts } = useMsal();
  const [role,setRoles] = useState(null);

useEffect(() => {
  showLoading();
  const loginDetails = async () => {
    if (accounts.length > 0) {
        const response = await accessRoles(instance,accounts);
        if(response){
          const roles =  response.idTokenClaims.roles[0] || [];
           saveUserDetails(roles);
           hideLoading();
        }
    }
  }
    loginDetails();
}, [instance, accounts,getCurrentUser()]);

   function saveUserDetails (userResponce) {
      if(userResponce){
          setRoles(userResponce);
      }
    }

  function getCurrentUser() {
    function checkPropertyValue(obj, value) {
         for (let key in obj) {
                 if (obj[key] === value) {
                    return true; // Found the value in the object's properties
                }
            }
        return false; // Value not found in the object's properties
      }
      if (checkPropertyValue(userKinds,role)) {
        return role;
      } else {
          return false;
      }
  }
  
  function canUpload(folderPath) {
    if (user === userKinds.MODULE_OWNER  || user === userKinds.PROMOTERS || user === userKinds.APPROVERS) {
      if(folderPath){
        
        if(folderPath.toLowerCase().includes("submission")){
          if (folderPath.split('/').pop() === "Archive") {
            return false;
          } else if (folderPath.split('/').pop() === "Submission") {
            return false;
          } else {
              // Check if folderPath ends with "/Submission" and has exactly one folder after "Submission"
              if (folderPath.includes("/Submission") && folderPath.split('/').length === 3) {
                return true;
              } else {
                return false;
              }
          }
        } else if ((folderPath.toLowerCase().includes("release") && !folderPath.toLowerCase().includes("releasedocs")) || folderPath.toLowerCase().includes("prerelease") || folderPath.toLowerCase().includes("validation")) {
          return false;
        } else {
          return true;
        }
      }
      return true
    } 
    return false;
  }

  function canCreateFolder(folderPath) {
    if ( user === userKinds.PROMOTERS || user === userKinds.APPROVERS) {
      if(folderPath){
        if(folderPath.toLowerCase().includes("submission")){
              if(folderPath.split('/').pop() === "Submission"){
                return true;
              } else {
                return false;
              }
        } else if ((folderPath.toLowerCase().includes("release") && !folderPath.toLowerCase().includes("releasedocs")) || folderPath.toLowerCase().includes("prerelease") || folderPath.toLowerCase().includes("validation")) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    }
    return false;
  }

  function canApprove () {
    if (user === userKinds.PROMOTERS || user === userKinds.APPROVERS) {
      return true;
    } else {
      return false;
    }
  }


  function canDownload(path) {
    if (user === userKinds.INTEGRATION_USER || user === userKinds.PROMOTERS || user === userKinds.APPROVERS || user === userKinds.TESTING) {
      if(user === userKinds.TESTING || user === userKinds.INTEGRATION_USER){
         if(path.includes('BusinessRelease') || path.includes('Prerelease') || path.includes('Validation')){
          return true;
         } else {
          return false;
         }
      } else {
        return true;
      }
      
    }
    if (user === userKinds.MODULE_OWNER) {
      return true;
    }
    if (
      [userKinds.AFTERSALES, userKinds.MANUFACTURING, userKinds.VCAT].includes(user) &&
      path
    ) {
      if (path.toLowerCase().includes("release")) {
        return true;
      }
    }
    return false;
  }
  
  function isModuleOwner() {
    const userKind = getCurrentUser();
    return userKind === userKinds.MODULE_OWNER;
  }
 
  function isIntegrationUser() {
    const userKind = getCurrentUser();
    return userKind === userKinds.INTEGRATION_USER;
  }
 
  function isManufactoringUser () {
    const userKind = getCurrentUser();
    return userKind === userKinds.MANUFACTURING;
  }
  function isVCATUsers () {
    const userKind = getCurrentUser();
    return userKind === userKinds.VCAT;
  }
  function isAfterSalesUsers () {
    const userKind = getCurrentUser();
    return userKind === userKinds.AFTERSALES;
  }


  function foldersForModuleOwner(nestedFile) {
    // Check if the current user is a module owner
    // Assuming isModuleOwner function exists and correctly implemented
    if (isModuleOwner()) {
          return true;
    } else if(user === userKinds.TESTING || user === userKinds.INTEGRATION_USER){
      let isRelease = nestedFile.isFolder && nestedFile.path.split("/").pop() === "Submission";
      // If nestedFile meets any of the conditions, return null
      if (isRelease ) {
          return false;
      } else {
        return true;
      }
    } else if (isManufactoringUser() || isAfterSalesUsers() || isVCATUsers()) {
      let isRelease = nestedFile.isFolder && nestedFile.path.split("/").pop() === "Submission";
      // Check if nestedFile represents a folder with a name starting with "Validation" (case-insensitive)
      let isValidationRelease = nestedFile.isFolder && nestedFile.path.split("/").pop().toLowerCase().startsWith("validation");

      // If nestedFile meets any of the conditions, return null
      if (isRelease || isValidationRelease) {
          return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
}

  return {
    canUpload,
    canCreateFolder,
    canDownload,
    foldersForModuleOwner,
    isModuleOwner,
    isIntegrationUser,
    canApprove,
    saveUserDetails,
    getCurrentUser,
    role,
  };
}





