import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import RightContainer from '../RightContainer/RightContainer';
import fetchDataAllBranches from "../CommonComponent/AccessRepo/fetchDataAllBranches.js";
import CreateFolderForm from '../CommonComponent/AccessRepo/CreateFolderForm.js';
import ErrorPopup from '../CommonComponent/ModelPopup/ErrorPopup.jsx'
import { useUserAuthorization, userKinds } from '../../Hook/userRoles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import './MainScreen.css'

const MainScreen = () => {

  const { instance, accounts } = useMsal();
  const [trigger, setTriggerFetch] = useState(false);
  const [dataFrobranches, setdataFrobranches] = useState(null);
  const [activeFileContent, setActiveFileContent] = useState('');
  const [showCreateFolderForm, setShowCreateFolderForm] = useState(false);
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [triggerFetchData, settriggerFetch] = useState(false);

  const closeErrorPopup = () => {
    setIsErrorPopupOpen(false);
  };
  const closeErrorPopupOnRoles = () => {
    console.log('false');
  };
  const handleFileClick = (branch, objectId = "") => {
    setActiveFileContent(branch);
    settriggerFetch(previousState => !previousState);
    const nestedKey = branch + 'branch';
    const targetElement = document.getElementById(nestedKey);
    targetElement?.classList?.add('active');
    if (objectId !== null && branch !== null) {
      window.objectId = objectId;
      window.branch = branch;
    }
  };

  const { getCurrentUser } = useUserAuthorization({ user: "" });
  const userinFo = getCurrentUser();
  const userRole = useUserAuthorization({ user: userinFo });
  // Function to show loading animation
  function showLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'flex';
    }
  }

  // Function to hide loading animation
  function hideLoading() {
    var loadingContainer = document.getElementById('loadingContainer');
    if (loadingContainer && loadingContainer.style) {
      loadingContainer.style.display = 'none';
    }
  }

  // Memoize the AccessRepo component
  useEffect(() => {
    const fetchData = async () => {
      if (dataFrobranches === null && userRole) {
        showLoading();
        fetchDataAllBranches(instance, accounts)
          .then(modifiedData => {
            if (modifiedData) {
              if (modifiedData.error) {
                // setMessage('An unknown error encountered while fetching Program data. Please refresh the page or verify Program availability on DevOps.');
                // setIsErrorPopupOpen(true);
                hideLoading();
              } else if (modifiedData.status) {
                // setMessage(`An unknown error occurred while fetching Program data (Status Code: ${modifiedData.status}). Please refresh the page or verify Program availability on DevOps.`);
                // setIsErrorPopupOpen(true);
                hideLoading();
              } else {
                setdataFrobranches(modifiedData);
                setTriggerFetch(false);
                hideLoading();
              }
            }
          })
          .catch(error => {
            // setMessage('Unknown Eorror in fetching Program,Please refresh the page or verify Program availability on devops.');
            // setIsErrorPopupOpen(true);
          });
      }
    }
    fetchData();
  }, [dataFrobranches, trigger]);
  const handleCloseButton = () => {
    setShowCreateFolderForm(false)
    setdataFrobranches(null);
  }
  // Memoize activeFileContent and nestedData
  const memoizedActiveFileContent = useMemo(() => activeFileContent, [activeFileContent]);

  let isDragging = false;

  let dragPointer = document.getElementById('dragPointer');
  let leftContainer = document.getElementById('leftContainer');
  let rightSideContainer = document.getElementById('mainContainer');
  let listOrThumbnailView = document.getElementById(`fileContent${window.view}`);
  
  if (dragPointer) {
    dragPointer.addEventListener('mousedown', function (e) {
      isDragging = true;
      document.addEventListener('mousemove', resizeContainer);
      document.addEventListener('mouseup', stopResize);
    });
  }
  
  function resizeContainer(e) {
    if (isDragging) {
      if (leftContainer) {
        const newWidth = e.clientX;
        const widthInVW = (newWidth / window.innerWidth) * 100;
  
        // Calculate the zoom level
        const zoomLevel = window.outerWidth / window.innerWidth;
  
        // Set the new width of the left container
        leftContainer.style.flex = `0 0 ${widthInVW / zoomLevel}vw`;
        leftContainer.style.width = `${widthInVW}vw`;
        
        rightSideContainer = document.getElementById('mainContainer');
        listOrThumbnailView = document.getElementById(`fileContent${window.view}`);
        // Update the right container and list or thumbnail view based on the remaining space
        if (rightSideContainer) {
          const rightWidthVW = 100 - widthInVW; // Remaining width percentage
          rightSideContainer.style.flex = `0 0 ${rightWidthVW / zoomLevel}vw`;
          rightSideContainer.style.width = `${rightWidthVW}vw`;
        }
  
        if (listOrThumbnailView) {
          const listViewWidthVW = 100 - widthInVW; // Remaining width percentage
          listOrThumbnailView.style.flex = `0 0 ${listViewWidthVW / zoomLevel}vw`;
          listOrThumbnailView.style.width = `${listViewWidthVW}vw`;
        }
      }
    }
  }
  
  function stopResize() {
    isDragging = false;
    document.removeEventListener('mousemove', resizeContainer);
    document.removeEventListener('mouseup', stopResize);
  }
  
 
  const setShowCreateFolderFormS = () =>{
    setShowCreateFolderForm(true);
  }

  function minimizeBranch(filename, objectId) {
    const nestedKey = filename + 'branch';
    let targetElement = document.getElementById(nestedKey);
    const existingWrapper = targetElement.nextElementSibling;
    if (existingWrapper && existingWrapper?.classList?.contains('nestedWrapper')) {
      if (existingWrapper && existingWrapper?.classList?.contains('d-none')) {
        existingWrapper?.classList?.remove('d-none');
        document.getElementById(`plusMark` + filename).style.transform = 'rotate(90deg)';
        const hasActiveChild = existingWrapper.querySelector('.active');
        if (hasActiveChild) {
          targetElement?.classList?.remove('active');
        }
      } else {
        existingWrapper?.classList?.add('d-none');
        document.getElementById(`plusMark` + filename).style.transform = '';
        const hasActiveChild = existingWrapper.querySelector('.active');
        if (hasActiveChild) {
          targetElement?.classList?.add('active');
        }
      }
    } else {
      handleFileClick(filename, objectId);
    }
  }

  return (
    <AuthenticatedTemplate>
      {userRole.getCurrentUser() &&
        <div >
          {dataFrobranches && <RightContainer branchNameS={memoizedActiveFileContent} rootPath={''} userRole={userRole} triggerFetchRecived={triggerFetchData} handleFileClick={handleFileClick} dataFrobranches={dataFrobranches} memoizedActiveFileContent={memoizedActiveFileContent} minimizeBranch={minimizeBranch} setShowCreateFolderFormS={setShowCreateFolderFormS}/>}
          {showCreateFolderForm && (
            <CreateFolderForm onCloseButton={() => {
              handleCloseButton()
            }
            }
              newBranch={"Branch"} />
          )}
        </div>
      }
      {isErrorPopupOpen && (
        <ErrorPopup message={message} onClose={closeErrorPopup} />
      )}
      {!userRole.getCurrentUser() &&
        <ErrorPopup message={'The roles assigned to your account seem invalid. Please check with admin and try again.'} onClose={closeErrorPopupOnRoles} />
      }
    </AuthenticatedTemplate>
  );
}

export default MainScreen;
