import React, { useState, useEffect } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { InteractiveBrowserCredential } from '@azure/identity';

const AzureBlobStatusChecker = () => {
    const [accountStatus, setAccountStatus] = useState('');
    const [loading, setLoading] = useState(false);

    const accountName = process.env.REACT_APP_ACCOUNTNAME; // Azure Storage account name
    const clientId = process.env.REACT_APP_CLIRNTID_URL; // Azure AD application client ID
    const containerName = 'your-container-name'; // Replace with your container name

    const checkAccountStatus = async () => {
        setLoading(true);
        try {
            // Use InteractiveBrowserCredential for authentication with clientId
            const credential = new InteractiveBrowserCredential({ 
                clientId, 
                tenantId: process.env.REACT_APP_TENANTID_URL 
            });
            
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );

            // Attempt to list the containers
            const containerList = blobServiceClient.listContainers();
            const containerArray = [];

            for await (const container of containerList) {
                containerArray.push(container.name);
            }

            setAccountStatus(`Account "${accountName}" is live. Containers: ${containerArray.join(', ')}`);
        } catch (error) {
            console.error('Error checking account status:', error);
            setAccountStatus('Error checking account status. Please check your Azure AD configuration.');
        } finally {
            setLoading(false);
        }
    };

    const uploadBlob = async (file) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container
            const containerClient = blobServiceClient.getContainerClient(containerName);
    
            // Create the container if it doesn't exist
            await containerClient.createIfNotExists();
    
            // Get a block blob client
            const blobName = file.name; // Use the file name as the blob name
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
            // Upload the file
            const uploadBlobResponse = await blockBlobClient.uploadBrowserFile(file);
    
            console.log('Blob uploaded successfully. Request ID:', uploadBlobResponse.requestId);
            return `Upload successful! Blob URL: https://${accountName}.blob.core.windows.net/${containerName}/${blobName}`;
        } catch (error) {
            console.error('Error uploading blob:', error);
            return 'Error uploading blob. Please check your configuration and try again.';
        }
    };

    const getBlobMetadata = async (blobName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container
            const containerClient = blobServiceClient.getContainerClient(containerName);
            
            // Get a reference to the blob
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
            // Fetch the blob properties (which includes metadata)
            const blobProperties = await blockBlobClient.getProperties();
            
            console.log('Blob metadata:', blobProperties.metadata);
            return blobProperties.metadata;
        } catch (error) {
            console.error('Error fetching blob metadata:', error);
            return null; // Or handle the error as needed
        }
    };
    const updateBlobMetadata = async (blobName, newMetadata) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container
            const containerClient = blobServiceClient.getContainerClient(containerName);
            
            // Get a reference to the blob
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
            // Update the blob's metadata
            await blockBlobClient.setMetadata(newMetadata);
    
            console.log('Blob metadata updated successfully.');
            return 'Metadata updated successfully!';
        } catch (error) {
            console.error('Error updating blob metadata:', error);
            return 'Error updating metadata. Please check your configuration and try again.';
        }
    };
    
    const downloadBlob = async (blobName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container
            const containerClient = blobServiceClient.getContainerClient(containerName);
            
            // Get a reference to the blob
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
    
            // Create a URL for the blob
            const downloadUrl = blockBlobClient.url;
    
            // Trigger download
            const response = await fetch(downloadUrl);
            const blobData = await response.blob();
    
            // Create a link element for downloading
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blobData);
            link.download = blobName; // Use blob name as the download filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Cleanup
    
            console.log('Blob downloaded successfully.');
        } catch (error) {
            console.error('Error downloading blob:', error);
        }
    };
    
    const downloadFileFromBlob = async (filePath) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container
            const containerClient = blobServiceClient.getContainerClient(containerName);
            
            // Get a reference to the blob (file)
            const blockBlobClient = containerClient.getBlockBlobClient(filePath);
    
            // Create a URL for the blob
            const downloadUrl = blockBlobClient.url;
    
            // Trigger download
            const response = await fetch(downloadUrl);
            if (!response.ok) {
                throw new Error(`Failed to download file: ${response.statusText}`);
            }
            const blobData = await response.blob();
    
            // Create a link element for downloading
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blobData);
            link.download = filePath.split('/').pop(); // Use the file name as the download filename
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Cleanup
    
            console.log('File downloaded successfully.');
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };
    
    const archiveBlob = async (blobName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
        const archiveContainerName = 'archive'; // Archive container name
    
        try {
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            const containerClient = blobServiceClient.getContainerClient(containerName);
            const archiveContainerClient = blobServiceClient.getContainerClient(archiveContainerName);
    
            // Create archive container if it doesn't exist
            await archiveContainerClient.createIfNotExists();
    
            // Get the current timestamp
            const timestamp = new Date().toISOString().replace(/[:.-]/g, "_");
            const archiveBlobName = `${timestamp}_${blobName}`;
    
            // Copy the blob to the archive
            await archiveContainerClient.getBlockBlobClient(archiveBlobName).startCopyFromURL(await containerClient.getBlockBlobClient(blobName).url);
            
            console.log('Blob archived successfully:', archiveBlobName);
            
            // Manage maximum archived blobs
            const blobs = archiveContainerClient.listBlobsFlat();
            const blobList = [];
            for await (const blob of blobs) {
                blobList.push(blob.name);
            }
    
            // Sort blobs by name (which includes timestamp) and delete the oldest if necessary
            blobList.sort();
            while (blobList.length > 5) {
                const oldestBlob = blobList.shift(); // Get the oldest blob name
                await archiveContainerClient.getBlockBlobClient(oldestBlob).delete();
                console.log('Deleted oldest archive blob:', oldestBlob);
            }
            
        } catch (error) {
            console.error('Error archiving blob:', error);
        }
    };
    
    const deleteBlob = async (blobName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
        const containerName = 'your-container-name'; // Replace with your container name
    
        try {
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            const containerClient = blobServiceClient.getContainerClient(containerName);
            await containerClient.getBlockBlobClient(blobName).delete();
            console.log('Blob deleted successfully:', blobName);
            
            // Archive the deleted blob
            await archiveBlob(blobName);
            
        } catch (error) {
            console.error('Error deleting blob:', error);
        }
    };
    
    const getFolderStructure = async (containerName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
    
        try {
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            const containerClient = blobServiceClient.getContainerClient(containerName);
            
            // List blobs in the container
            const folderStructure = {};
            for await (const blob of containerClient.listBlobsFlat()) {
                const parts = blob.name.split('/');
                let current = folderStructure;
    
                // Build the folder structure
                parts.forEach((part, index) => {
                    if (!current[part]) {
                        current[part] = index === parts.length - 1 ? null : {};
                    }
                    current = current[part];
                });
            }
    
            console.log('Folder structure:', JSON.stringify(folderStructure, null, 2));
            return folderStructure;
        } catch (error) {
            console.error('Error retrieving folder structure:', error);
        }
    };

    const createContainer = async (containerName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
    
        try {
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            const containerClient = blobServiceClient.getContainerClient(containerName);
            
            // Create the container if it doesn't exist
            await containerClient.createIfNotExists();
            console.log(`Container '${containerName}' created or already exists.`);
        } catch (error) {
            console.error('Error creating container:', error);
        }
    };
    
    const getContainerNames = async () => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container client
            const containerClient = blobServiceClient.getContainerClient();
    
            // List all containers
            const containers = containerClient.listContainers();
            const containerNames = [];
    
            for await (const container of containers) {
                containerNames.push(container.name);
            }
    
            console.log('Container names retrieved successfully:', containerNames);
            return containerNames;
        } catch (error) {
            console.error('Error retrieving container names:', error);
            return 'Error retrieving container names. Please check your configuration and try again.';
        }
    };

    const getBlobsFromContainer = async (containerName) => {
        const accountName = process.env.REACT_APP_ACCOUNTNAME; // Your Azure Storage account name
        const clientId = process.env.REACT_APP_CLIRNTID_URL; // Your Azure AD application client ID
    
        try {
            // Authenticate and create BlobServiceClient
            const credential = new InteractiveBrowserCredential({ clientId });
            const blobServiceClient = new BlobServiceClient(
                `https://${accountName}.blob.core.windows.net`,
                credential
            );
    
            // Get a reference to the container client
            const containerClient = blobServiceClient.getContainerClient(containerName);
    
            // List all blobs in the container
            const blobs = containerClient.listBlobsFlat();
            const blobNames = [];
    
            for await (const blob of blobs) {
                blobNames.push(blob.name);
            }
    
            console.log(`Blob names in container '${containerName}' retrieved successfully:`, blobNames);
            return blobNames;
        } catch (error) {
            console.error('Error retrieving blob names:', error);
            return 'Error retrieving blob names. Please check your configuration and try again.';
        }
    };
    
    
    useEffect(() => {
        checkAccountStatus();
    }, []);

    return (
        <div>
            <h1>Check Azure Blob Storage Account Status</h1>
            {loading ? <p>Loading...</p> : <p>{accountStatus}</p>}
        </div>
    );
};

export default AzureBlobStatusChecker;
